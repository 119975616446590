var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"11e10d48cc368f75291f9a1d81c86111b22c61d9"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';

import {
  NEXT_PUBLIC_SENTRY_DSN,
  NEXT_PUBLIC_SENTRY_ENV,
  NEXT_PUBLIC_SENTRY_TRACES_SAMPLE_RATE,
} from '@/env';

if (NEXT_PUBLIC_SENTRY_DSN) {
  Sentry.init({
    dsn: NEXT_PUBLIC_SENTRY_DSN,
    integrations: [
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    environment: NEXT_PUBLIC_SENTRY_ENV,
    tracesSampleRate: NEXT_PUBLIC_SENTRY_TRACES_SAMPLE_RATE,
    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
  });
}
